.product-tour-label {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 120%;
  letter-spacing: -0.01em;
  background: linear-gradient(171.75deg, #FF0095 9.92%, #FFCDFF 100.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.try-demo-btn {
  padding: 12px 24px;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  color: #EBEBEB;
  background: transparent;
  cursor: pointer;
}

.local-installer-btn {
  padding: 12px 24px;
  border: 1px solid #FF3AB3;
  border-radius: 4px;
  color: #FF3AB3;
  background: transparent;
  cursor: pointer;
}

.btns-container {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 10px;
}

.demo-page {
  height: 100%;
  background: #0B0D15;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #0B0D15;
  position: relative;
  width: auto;
  z-index: 1;
  padding-top: 200px;
}

.linear-gredient-content-container {
  position: absolute;
  width: 50%;
  height: 379.05px;
  background: linear-gradient(240.85deg, #FF0095 38.35%, #FFCDFF 66.9%);
  filter: blur(250px);
  z-index: 1;
}

.top-section {
  display: flex;
  justify-content: center;
  gap: 230px;
}

.reach-out-btn {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.01em;
  padding: 12px 24px;
  border: 1px solid #EBEBEB;
  color: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}


.subscribe-section{
  background: #0B0D15;
  padding: 20px;
  height: 200px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.subscribe-container-bg{
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 20px;
  background: #6B006D;
  filter: blur(265px);
  z-index: 1;
}

.subscribe-text{
  font-size: 24px;
  font-family: 'Work Sans';
  color: #FFFFFF;
  margin-bottom: 10px;
  z-index: 1;
}

.email-container{
  display: flex;
  align-items: center;
  border: 1px solid #E0E0E0;
  padding: 3px;
  border-radius: 8px;
  z-index: 1;
}

.email-input{
  padding: 6px 8px;
  border: none;
  background: transparent;
  color: #FFFFFF;
  outline: none;
  width: 300px;
  font-size: 16px;
  font-family: 'Work Sans';
}

.subscribe-button{
  background: #FFFFFF;
  color: #0B0D15;
  font-size: 16px;
  font-family: 'Work Sans';
  font-weight: 500;
  border-radius: 4px;
  padding: 4px 12px;
  border: none;
  cursor: pointer;
}

@media (max-width: 1150px) {
    .demoFrame{
      width: 70%;
      margin-top: 20px;
    }
    .top-section{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0;
    }
}

@media (max-width: 768px) {
  .product-tour-label {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    background: linear-gradient(171.75deg, #FF0095 9.92%, #FFCDFF 100.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .try-demo-btn ,.local-installer-btn{
    padding: 5px 10px;
    font-size: 15px;
  }
  .reach-out-btn{
    padding: 8px 9px;
    font-size: 15px;
  }
}