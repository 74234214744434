.jigsaw_navbar {
    width: 100%;
    background-color: #0B0D15;
    padding: 24px;
    position: fixed;
    top: 0;
    z-index: 2;
}

.jigsaw_navbar_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1550px;
    margin: 0 auto;
    position: relative;
}

.jigsaw_logo_container {
    display: flex;
    align-items: center;
}

.jigsaw_logo {
    height: 40px;
}

.jigsaw_nav_items {
    display: flex;
    align-items: center;
    gap: 24px;
}

.jigsaw_nav_item {
    color: #B1B1B1;
    cursor: pointer;
    font-size: 16px;
    transition: color 0.3s ease;
    text-decoration: none;
}

.jigsaw_nav_item:hover {
    color: #FFFFFF;
}

.jigsaw_scanner {
    display: flex;
    align-items: center;
    gap: 8px;
}

.jigsaw_beta_tag {
    background-color: #C735B3;
    color: #E9EDF5;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 4px;
}

.jigsaw_button_container {
    display: flex;
    align-items: center;
    gap: 16px;
}

.jigsaw_demo_button {
    padding: 8px 16px;
    border: 1px solid #FFFFFF;
    background: transparent;
    color: #FDFDFD;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
    text-decoration: none;
}

.jigsaw_demo_button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.jigsaw_login_button {
    padding: 8px 16px;
    background-color: #6C63FF;
    color: #FDFDFD;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.2s ease;
}

.jigsaw_login_button:hover {
    background-color: #5A52D5;
}

.jigsaw_mobile_menu_icon {
    display: none;
    cursor: pointer;
}

.jigsaw_mobile_menu_overlay {
    display: none;
}

@media screen and (max-width: 768px) {
    .jigsaw_navbar {
        padding: 16px 24px;
    }

    .jigsaw_mobile_menu_icon {
        display: block;
        z-index: 20;
        position: relative;
    }

    .jigsaw_desktop_nav,
    .jigsaw_desktop_buttons {
        display: none;
    }

    .jigsaw_mobile_menu_overlay {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 60%;
        background-color: #0B0D15;
        z-index: 10;
        overflow-y: auto;
        padding-top: 88px;
        animation: slideIn 0.3s ease;
    }

    @keyframes slideIn {
        from {
            opacity: 0;
            transform: translateY(-20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .jigsaw_mobile_nav_items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        padding: 10px 20px;
        text-decoration: none;
    }

    .jigsaw_mobile_nav_items .jigsaw_nav_item {
        color: #B1B1B1;
        font-size: 16px;
        margin-bottom: 10px;
        transition: color 0.3s ease;
    }

    .jigsaw_mobile_nav_items .jigsaw_nav_item:hover {
        color: #FFFFFF;
    }

    .jigsaw_mobile_buttons {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        max-width: 180px;
    }

    .jigsaw_mobile_buttons .jigsaw_demo_button,
    .jigsaw_mobile_buttons .jigsaw_login_button {
        width: 100%;
        padding: 12px 16px;
        font-size: 14px;
    }

    .jigsaw_scanner {
        align-items: center;
        gap: 8px;
    }
}

@media screen and (max-width: 480px) {
    .jigsaw_navbar {
        padding: 20px 16px;
    }

    .jigsaw_logo {
        height: 32px;
    }

    .jigsaw_mobile_menu_overlay {
        padding: 30px 30px;
    }

    .jigsaw_mobile_nav_items .jigsaw_nav_item {
        font-size: 16px;
    }
}