.activity_log_wrapper {
  padding: 0 0.5rem;
  display: flex;
  background-color: #2f2f2f;
  flex-direction: column;
}

.activity_log_content {
  height: fit-content !important;
  background-image: linear-gradient(
    rgba(217, 217, 217, 0.1),
    rgba(217, 217, 217, 0.1)
  );
  background-size: 2px 100%;
  background-repeat: no-repeat;
  background-position: 2.5%;
  max-height: 410px;
  overflow-y: scroll;
  padding-right: 10px;
}

.activity_log_content::-webkit-scrollbar {
  width: 2px;
  background-clip: content-box;
  padding: 10px;
}

.activity_log_content::-webkit-scrollbar-track {
  background-color: transparent;
}

.activity_log_content::-webkit-scrollbar-thumb {
  background: #444;
  background-clip: content-box;
  border-radius: 100px;
}

.activity_log_wrapper > ul:first-child li button {
  color: #f0f0f0;
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.18px;
  opacity: 0.8;
}

.activity_log_wrapper > ul:first-child li button.active-tab {
  color: #00b2d9 !important;
}

.activity_log_wrapper > div {
  flex: 1;
}

.activity-log-icon {
  padding: 10px;
  background-color: #cccae1;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activity-log-content {
  color: #f0f0f0;
  opacity: 0.6;
}

.activity-log-time {
  font-size: 10px;
  font-weight: 400;
  opacity: 0.6;
  min-width: 100px;
  text-align: right;
  color: #f0f0f0;
}

.uploads_log_content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 1rem;
  max-height: 360px;
  overflow-y: scroll;
  padding-right: 10px;
  margin-top: 1rem;
}

.uploads_log_content::-webkit-scrollbar {
  width: 2px;
  background-clip: content-box;
  padding: 10px;
}

.uploads_log_content::-webkit-scrollbar-track {
  background-color: transparent;
}

.uploads_log_content::-webkit-scrollbar-thumb {
  background: #444;
  background-clip: content-box;
  border-radius: 100px;
}

.upload-file {
  width: 100%;
  height: 100px;
  background: #3d3d3d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-file-name {
  color: #7e7e7e;
}

.coming-soon-banner{
  background-color: #f0f8ff;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.coming-soon{
  font-size: 28px;
  color: #197499;
  font-weight: 700;
}