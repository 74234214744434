.localInstallerBody{
    background: #2E0249;
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-radius: 24px;
}
.localInstallerDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.getFreeLabel{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #EEEEEE;
    width: 284.2px;
    text-align: center;
    opacity: 0.7;
}
.shareEmailLabel{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #EEEEEE;
    opacity: 0.7;
    width: 281.48px;
}
.localInstallerForm{
    background: #EEEEEE;
    border-radius: 24px;
    width: 601px;
    padding: 32px;
    margin: auto;
    margin-top: 30px;
}
.enterLabel{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2E0249;
    width: 130px;
    height: 23px;
}
.inputEmail{
    width: 537px;
    height: 27px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #989898;
    outline: none;
    border: none;
    background: transparent;
    box-shadow: 0px 1px rgba(0,0,0,.5);
    caret-color: #A91079;
    margin-top: 5px;
}
.downloadButton{
    padding: 16px 64px;
    width: 537px;
    height: 53px;
    background: #6C63FF;
    border-radius: 16px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #EEEEEE;
}
.downloadBtnContainer{
    margin-top: 25px;
}
.sentLinkContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.linkSentLabel1{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    color: #EEEEEE;
    opacity: 0.7;
    width: 411.2px;
    height: 58px;
}
.linkSentLabel2{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #EEEEEE;
    opacity: 0.7;
    width: 281.48px;
    height: 38px;
}

.modal-local-installer{
    background: none;
    border-radius: 24px;
}

@media (max-width : 991px){
    .inputEmail , .downloadButton , .localInstallerForm{
        width: 100%;
    }
}
@media (max-width : 570px){
    .downloadButton{
        padding: 0px;
    }
    .linkSentLabel1{
        font-size: 30px;
    }
    .getFreeLabel{
        font-size: 32px;
    }
    .localInstallerForm{
        padding: 20px;
    }
}