.warning-msg-modal {
    background: transparent;
}

.warning-msg-modal-header {
    border: none;
    background: rgba(18, 20, 31, 1);
}

.warning-msg-modal-body {
    background: rgba(18, 20, 31, 1);
    color: rgba(159, 161, 166, 1);
    font-family: 'Work Sans';
    text-align: justify;
    padding-bottom: 0;
}

.warning-msg-modal-footer {
    border: none;
    background: rgba(18, 20, 31, 1);
}

.warning-msg-modal-title {
    max-width: 24ch;
}

@media screen and (min-width: 375px) and (max-width: 425px) {
    .warning-msg-modal-title {
        max-width: 26ch;
    }
}

