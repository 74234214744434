.res-header{
    background: rgba(18, 20, 31, 1);
    border-color: rgba(18, 20, 31, 1);
    color: white;
    opacity: 0.95;
    font-size: large;
    font-weight: bold;
    border-bottom: 1px solid rgba(159, 161, 166, 0.4) !important;
}

.res-header .btn-close {
    filter: invert(1);
}

.res-body{
    height: fit-content;
    max-height: 700px;
    overflow-y: auto;
    background: rgba(18, 20, 31, 1);
    color: rgba(159, 161, 166, 1);
    border-color: rgba(18, 20, 31, 1);
}
.res-body {
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    color: rgba(159, 161, 166, 1);
    background: rgba(18, 20, 31, 1);
}

.res-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.res-footer{
    background: rgba(18, 20, 31, 1);
    border-color: rgba(18, 20, 31, 1);
    opacity: 0.95;
    color: rgba(159, 161, 166, 1);
    font-style: italic;
    font-size: small;
    border-top: 1px solid rgba(159, 161, 166, 0.4) !important;
}

.footer-button {
    color: white;
    border-color: rgba(63, 81, 181, 1);
    background-color: rgba(63, 81, 181, 1);
}

.footer-button:hover {
    border-color: rgba(63, 81, 181, 1);
    background-color: rgba(63, 81, 181, 1);
}

.footer-button:disabled {
    background-color: rgba(63, 81, 181, 1) !important;
    border-color: rgba(63, 81, 181, 1) !important;
    opacity: 0.5;
    cursor: not-allowed;
}

.subtitle {
    color: rgba(159, 161, 166, 1);
}
.modal-content{
    background: rgba(18, 20, 31, 1);
}