.beta-badge {
    background: #c735b3;
    padding: 4px 10px;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    margin-left: 8px;
}

.footer-container {
    width: 100%;
    background-color: #0B0D15;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.footer-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem;
    gap: 2rem;
    margin-bottom: 0;
}
.footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.footer-logo {
    margin-bottom: 1rem;
    width: 120px;
    height: 50px;
}
.footer-text, .footer-heading {
    font-size: 24px;
    padding-top: 8px;
    line-height: 1.5;
    text-align: left;
    color: #7F7F7F;
    font-family: 'Work Sans', sans-serif;
}
.footer-copyright {
    font-size: 20px;
    line-height: 1.2;
    color: #7F7F7F;
    font-family: 'Work Sans', sans-serif;
}
.footer-links {
    margin-top: 1.5rem;
}
.footer-link, .footer-email {
    text-decoration: none;
    font-weight: 500;
    font-family: 'Work Sans', sans-serif;
    line-height: 1.5;
}
.footer-link {
    color: #BCB5FF;
    margin-top: 0.5rem;
}
.footer-email {
    font-size: 20px;
    color: #B4B4B4;
}
.footer-heading {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    font-family: 'Work Sans', sans-serif;
}
@media (min-width: 375px) {
    .footer-section {
      padding: 1rem 2rem;
    }
    .footer-links {
        margin-top: 0;
        text-align: left;
      }
}
@media (max-width : 426px){
    .links-container{
        flex-direction: column;
    }
}
@media (min-width: 768px) {
    .footer-section {
      flex-direction: row;
      justify-content: space-between;
      padding: 2rem 2rem;
    }
    .footer-links {
      margin-top: 0;
    }
}
@media (min-width: 1024px) {
    .footer-section {
        padding: 1.6rem 13.5rem 0;
    }
}
