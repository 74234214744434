.demoModalBody{
    background: #2E0249;
    display: flex;
    justify-content: space-around;
    gap: 30px;
    padding: 40px;
    border-radius: 24px;
    width: 800px;
    height: 627px;
}

.demoModalBodyfirstsection{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 555px;
}
.demoModalForm{
    display: flex;
    flex-direction: column;
    gap: 35px;
    background: #EEEEEE;
    border-radius: 24px;
    width: 435px;
    height: 551px;
    padding: 32px;
}
.reachLabel{
    width: 250px;
    height: 96px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #EEEEEE;
    opacity: 0.7;
}
.linkedInLogo{
    height: 27px;
    width: 28px;
}
.linkedInLogoContainer{
    width: 49px;
    height: 49px;
    padding: 11px;
}
.linkedInLogoContainer:hover{
    border-radius: 50px;
    background-color: #6C63FF;
}
.formLabel{
    color: #2E0249;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    width: 212px;
    height: 23px;
}
.demoModalInput{
    width: 100%;
    height: 27px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #2E0249;
    outline: none;
    border: none;
    background: transparent;
    box-shadow: 0px 1px rgba(0,0,0,.5);
    caret-color: #A91079;
}
.demoModalInput:focus,textarea:focus {
    box-shadow: 0px 2px #A91079;
}
.demoModalTextArea{
    width: 100%;
    height: 84px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #2E0249;
    outline: none;
    border: none;
    background: transparent;
    box-shadow: 0px 1px rgba(0,0,0,.5);
    caret-color: #A91079;
}
.sendMsgButton{
    padding: 16px 64px;
    width: 100%;
    height: 56px;
    background: #6C63FF;
    color: #EEEEEE;
    border-radius: 16px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
}
.sendMessageLogo{
    position: absolute;
    top: 17px;
    left: 25%;
}
.modal-content{
    background: transparent;
    border: none;
}


@media (max-width: 991px) {
    .demoModalBody{
        flex-direction: column;
        width: auto;
    }
    .demoModalBodyfirstsection{
        flex-direction: row;
        align-items: center;
        height: auto;
    }
    .demoModalForm{
        gap: 17px;
        height: auto;
        width: auto;
        margin: auto;
    }
    .sendMessageLogo{
        left: 20%;
    }
    .reachLabel{
        width: 210px;
        height: auto;
        font-size: 25px;
    }
}
@media (max-width: 480px) {
    .sendMessageLogo{
        left: 15%;
        width: 16px;
        top: 35%;
    }
    .sendMsgButton{
        font-size: 15px;
    }
}
@media (max-width : 380px){
    .sendMsgButton{
        font-size: 14px;
        font-weight: 600;
        padding-right: 45px;
        margin: auto;
    }
}