.footer-wrapper {
  position: relative;
  width: fit-content;
  bottom: 0;
  right: 0%;
  z-index: 101 !important;
}

.footer-grid {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  height: 70px;
  padding-bottom: 10px;
}

.btn-wrapper {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.disabled-link {
  background-color: #191919; /* Light gray color */
  cursor: not-allowed;
  pointer-events: none; /* Prevents interaction */
}

.btn {
  background: none;
  box-shadow: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #3d3d3d;
}

.search-grid {
  display: grid;
  grid-template-columns: 5% 95%;
  border: 1px solid #3d3d3d;
  border-radius: 0.5rem;
  background: #272727;
  backdrop-filter: blur(7.5px);
  color: #fff;
  max-width: 915px;
  justify-content: flex-end;
}

.history {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 0.2rem;
}

.input-btn-wrapper {
  box-sizing: border-box;
  width: 100%;
  border-left: 1px solid #3d3d3d;
  display: grid;
  grid-template-columns: 45% 38% 10% 2%;
  padding: 0.5rem;
  column-gap: 1rem;
  justify-content: center;
}

.all-btn-wrapper {
  border: 1px solid #626262;
  border-radius: 0.5rem;
  display: grid;
  width: 270px;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.all-btn-wrapper .group-btn {
  height: 100%;
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.all-btn-wrapper .group-btn:not(:first-child) {
  border-left: 1px solid #626262;
}

.group-btn.active {
  border-left: none !important;
  border: 1px solid #5a46ff;
  border-radius: 0.5rem;
  background: #5a46ff;
}

.group-btn.active + .group-btn {
  border-left: none !important;
}

.li-group > div {
  background: transparent !important;
  color: #fff;
}

.ai-btn {
  display: flex;
  text-align: center;
  background: linear-gradient(90deg, #1b05f9 0%, #f60 100%);
  color: #fff;
  border: 1px solid rgba(128,128,128,1);
  border-radius: 0.5rem;
  text-decoration: none;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-weight: 600;
  pointer-events: all;
  font-size: 12px;
  height: 100%;
  width: 100%;
}

.ask-ai-expand-collapse{
  height: 40px;
  width: 100px;
  position: absolute;
  bottom: 25px;
  right: 28px
}

.ai-btn:hover {
  border: 1px solid white;
}

@media (max-width: 768px) {
  .ai-btn {
    left: 0px !important;
  }
}

.custom-input input {
  border-radius: 0.5rem;
  border: 1px solid #3d3d3d;
  background: rgba(0, 0, 0, 0.2) !important;
  padding: 0.4rem 1rem !important;
  max-width: 31.5rem;
  background: transparent;
  color: #fff;
  width: 30rem;
}

.custom-input input:focus {
  color: #fff;
  opacity: 1 !important;
}

.custom-input input::placeholder {
  color: #6e6e6e;
}

.custom-input input:focus {
  box-shadow: none;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

#search-input-field {
  border-radius: 8px;
  border: 1px solid #3d3d3d;
  background: rgba(68, 70, 83, 1) !important;
  transform: translate3d(-64px, -57px, 0px) !important;
  width: 500px !important;
}

.advance-search-input-field {
  position: absolute;
  transform: translate3d(0%, -102%, 0px) !important;
}

.advance-questions-field-general {
  position: absolute;
  transform: translate3d(107%, -102%, 0px) !important;
}
.advance-questions-field-cost {
  position: absolute;
  transform: translate3d(145%, -102%, 0px) !important;
}

/* Customize the scrollbar */
#search-input-field::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

/* Customize the scrollbar track */
#search-input-field::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the track */
}

/* Customize the scrollbar thumb (the draggable part) */
#search-input-field::-webkit-scrollbar-thumb {
  background-color: #3f3f3f; /* Set the color of the thumb */
  border-radius: 100px; /* Add rounded corners to the thumb */
  background-clip: padding-box;
}

#question-menu-ul::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

/* Customize the scrollbar track */
#question-menu-ul::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the track */
}

/* Customize the scrollbar thumb (the draggable part) */
#question-menu-ul::-webkit-scrollbar-thumb {
  background-color: #3f3f3f; /* Set the color of the thumb */
  border-radius: 100px; /* Add rounded corners to the thumb */
  background-clip: padding-box;
}

#search-input-field .menu-ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

#search-input-field .menu-ul li {
  padding: 1rem;
  border-bottom: 1px solid #3f3f3f;
}

#search-input-field hr {
  margin: 0;
  color: #3f3f3f;
  opacity: 1;
}

#search-input-field .menu-ul li a {
  color: #fff;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  opacity: 0.6;
  margin: 0px;
  padding: 0px;
  white-space: normal;
  word-break: normal;
  overflow-wrap: break-word;
}

#search-input-field .menu-ul li:hover {
  background: rgba(51, 52, 64, 0.6);
}

#search-input-field .menu-ul li a:focus,
#search-input-field .menu-ul li a:hover,
#search-input-field .menu-ul li a:active {
  text-decoration: none;
  background-color: transparent;
  opacity: 1 !important;
}

.expand-collapse-btn svg {
  transition: transform 0.7s;
  width: 20px;
  height: 20px;
}

.btn-open svg {
  transform: rotate(180deg);
}

.custom-heading {
  margin: 1rem 0rem 1rem 1rem;
  text-align: left;
  font-weight: 600;
  opacity: 0.7;
}

.custom-cross-svg {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}

@media screen and (min-width: 964px) {
  .search-grid {
    min-width: 915px;
  }
}
.thick-border{
  padding: .3rem;
  position: absolute;
  width: 400px;
  left: 0;
  border-radius: .3rem;
  margin-bottom: 22px;
  color: grey;
  /* background: #181818; */
  /* color: #fff; */
  /* color: grey; */
  bottom: 0;
  font-weight: 400;
  font-size: 13px;
}
.cancel-btn {
  border: 1px solid gray;
  border-radius: 5px
}
.input-focus {
   background-color: #161616 !important;
}
.input-blur{
  background-color: transparent;
}
.infra-view-bg {
  background-color: rgba(57, 59, 82, 1);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.history-bg {
  background-color: rgba(44, 45, 61, 1);
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.all-btn-wrapper-infra {
  background-color: rgba(39, 39, 39, 1);
}
.ai-btn-drag{
  pointer-events: none;
}
.main-footer{
  display: flex;
  justify-content: flex-end;
  padding-right: 455px;
}
.about-ask-ai-res{
  position: absolute;
  top: -132px;
  left: -1px;
  width: 14.5rem;
  font-size: 12px;
}
.response-popup{
  color: rgba(18, 20, 31, 0.9);
  display: flex;
  justify-content: space-between;
}
.ques-text{
  align-content: center;
  font-size: 12px;
  width: 80px;
}