.container-fluid {
  padding: 0;
  background: rgba(18,20,31,1);
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Work Sans', sans-serif !important;
}

.container-fluid2 {
  padding: 0;
  background: #0c0c0c;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Work Sans', sans-serif !important;
}

.inputStyle {
  color: #B1B2B3;
  background-color: transparent;
  display: flex;
  width: 300px;
  height: 56px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #595B5E;
}

ul.nav {
  list-style: none;
  display: flex;
}

ul.nav li {
  padding: 16px;
}

ul.nav-left {
  justify-content: flex-start;
}

ul.nav-right {
  justify-content: flex-end;
}

ul.nav-x {
  flex-direction: row;
  justify-content: center;
}

ul.nav-y {
  flex-direction: column;
}

ul.nav li.active {
  border-radius: 2px;
  background: #5a46ff;
}

/* .header {
  background-color: #0c0c0c;
  display: flex;
  color: #ffffff;
  align-items: center;
} */

.header-btn-div {
  display: flex;
  height: 100%;
}

.header-btn-div .main-btn {
  /* padding: 20px; */
  min-width: 77px;
  background: rgb(57, 57, 57);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-btn-div .main-btn a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.header-btn-div .main-btn.active {
  background: #5a46ff;
}

.header-btn-div .main-btn a svg {
  max-width: 16px;
  max-height: 16px;
}

.header-btn-div .main-btn a span {
  color: #fff;
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.vl {
  border-left: 1px solid #414141;
  height: 56px;
  margin: 0px 1rem;
}

.header-btn-div .main-btn.bg-black {
  background: #000;
}
.main-btn-infra{
  background-color: rgba(24, 25, 38, 1) !important;
}

.Documentationtab {
  width: 117px;
  height: 853px;
  background-color: #2f2f2f;
  color: #ffffff;
  font-size: 10px;
  font-weight: 400;
  font-family: 'inter', sans-serif;
}

.packageTab {
  height: 853px;
  background-color: #2f2f2f;
}

.header2 {
  background-color: #2f2f2f;
}

.header2 ul {
  padding: 9px 0 9px 0;
  margin: 0;
}

/*13-09-2023*/

.basic-navbar-nav {
  width: 100%;
  justify-content: space-between;
  height: inherit;
  padding: 0.5rem 1.5rem;
}

.cust-navbar {
  background-color: #2f2f2f;
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #565656;
  color: #fff;
}
.cust-navbar-infra{
  background-color: rgba(7, 8, 15, 1);
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid rgba(7, 8, 15, 1);
  color: #fff;
}

.cust-navbar .custom-navbar-nav>a {
  display: inherit;
  padding: 16px;
}

.cus-sidebar {
  /* background-color: #2f2f2f; */
  /* padding: 24px 0px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cus-sidebar>div {
  width: 100% !important;
}

.cus-sidebar-nav>div {
  width: 100% !important;
}

.cus-sidebar-active {
  background: #1b1b1b;
}

.cus-sidebar a {
  color: #fff;
  font-family: 'inter', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  opacity: 0.6;
}

.cus-sidebar a:focus,
.cus-sidebar a:hover {
  color: #fff !important;
  opacity: 1;
}

.cus-sidebar a:hover {
  color: #fff;
}

.cus-sidebar a img {
  width: 20px;
}

.blue-active {
  background-color: #5a46ff;
  border: 1px solid #5a46ff;
  border-radius: 2px;
}

.gray-border {
  border-radius: 4px;
  border: 1px solid #4e4e4e;
}

.btn-gradient {
  border-radius: 8px;
  border: 1px solid #808080;
  background: linear-gradient(90deg, #1b05f9 0%, #f60 100%);
  max-width: 66px;
  margin: 24px auto;
}

.custom-btn {
  border-radius: 8px;
  border: 1px solid #808080;
  background: transparent;
  max-width: 66px;
  padding: 16px !important;
  margin-right: 24px;
  min-width: 48px;
  min-height: 48px;
  display: flex;
}

.nav-dropdown a {
  color: #fff !important;
}

.search-modal-class {
  background: #151515;
  border-radius: 10px;
  color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #3d3d3d;
  max-width: 560px;
  text-align: left;
}

.search-ask-ai-res-modal {
  background: #151515;
  color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #3d3d3d;
  max-width: 560px;
  text-align: left;
  overflow-y: scroll;
  position: relative;
  border-top: none;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.search-modal-class-content {
  padding: 0.5rem 1rem;
}

.close-button {
  top: 0;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-top: 4px;
  display: flex;
  justify-content: end;
  gap: 15px;
}

.btn-close:hover {
  cursor: pointer;
}

.search-ask-ai-res-modal::-webkit-scrollbar {
  width: 2px;
  background-clip: content-box;
  padding: 10px;
}

.search-ask-ai-res-modal::-webkit-scrollbar-track {
  background-color: transparent;
}

.search-ask-ai-res-modal::-webkit-scrollbar-thumb {
  background: #444;
  background-clip: content-box;
  border-radius: 100px;
}

.response-copy{
  cursor: pointer;
  margin-left: 10px; /* Optional: Add some space between the item and the copy icon */
}

.response-copy img{
  width: 20px;
  filter: invert(1);
}

.process-time{
  font-size: 10px;
  text-align: right;
  width: 35%;
}

.search-modal-item {
  flex-grow: 1; /* Allow the item to take up remaining space */
  padding-bottom: 1rem;
  font-size: 0.875rem;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  opacity: 0.5;
  cursor: pointer;
  user-select: text;
}

.resize-handle {
  cursor: ns-resize;
  height: 35px;
  background: #151515;
  position: absolute;
  top: -10px;
  width: 100%;
  border-start-start-radius: 0.5rem;
  border-start-end-radius: 0.5rem;
  z-index: 99;
  border: 1px solid #3d3d3d;
}

.resize-ask-ai-res-modal{
  position: relative;
  max-height: 200px;
  padding-top: 26px;
}

.message-container {
  display: flex;
}

.warning-message{
  font-size: 10px;
  font-style: italic;
  color: red;
  width: 65%;
}

.res-try-again{
  display: flex;
  justify-content: end;
  align-items: end;
  margin-left: 30px;
}

.try-again-btn{
  border: 1px solid rgba(128, 128, 128, 1);
  background: linear-gradient(90deg, #1b05f9 0%, #f60 100%);
  color: #fff;
  border-radius: 0.4rem;
  font-weight: 600;
  transition: all 0.3s ease;
  font-size: 12px;
  padding: 6px;
  width: 90px;
}

.try-again-btn:hover{
  border: 1px solid white;
}
.search-modal-item:hover {
  opacity: 1;
}

.navigation-wrapper {
  height: 100%;
  gap: 1rem;
}
.navigation-wrapper-bg {
  /* background: #393939; */
  border-radius: 5px;
  overflow: hidden;
  display: flex;
}

.navigation-item {
  height: inherit;
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 16px; */
  transition: all 0.3s ease;
  border-radius: 4px;
}
.navigation-connect-item{
  height: inherit;
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  border-radius: 4px;
}
.Connect-btn{
  border: 1px solid #3d3d3d;
  background-color: #5a46ff;
  height: 37px;
  max-width: 110px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 4px;
}

.Connect-btn-img{
  filter: invert(1);
  opacity: 0.9;
}

.navigation-item-download svg {
  width: 16px;
  height: 16px;
}
.Connect-btn:hover{
  background-color: #5a46ff;
  border-radius: 6px;
}
.navigation-item:hover {
  /* background-color: #464646; */
  background-color: #5a46ff;
}

.navigation-item button {
  padding: 0px;
}

.navigation-connect-item-active{
  background-color: #5a46ff;
  border: none;
  border-radius: 4px;
}

.navigation-item-active {
  background-color: #5a46ff;
}

.download-btn-active {
  background-color: #5a46ff;
}

.navigation-item-active-black {
  background-color: #181818;
}

.download-btn{
  margin-top: 7px;
  height: 37px;
  border-radius: 6px;
  width: 130px;
  font-family: Work Sans;
  border: 1px solid #3d3d3d;
}

.download-btn:hover{
  background-color: #5a46ff;
}

.patch-dropdown-menu {
  background: #444;
}

.patch-dropdown-menu a {
  color: #fff !important;
  opacity: 0.6;
}

.patch-dropdown-menu a:hover {
  background: none !important;
  opacity: 1;
}

.patch-dropdown button,
.patch-dropdown button:hover,
.patch-dropdown button:active,
.patch-dropdown button:focus {
  background: none !important;
  border: none !important;
}

.patch-dropdown button {
  width: 100%;
  height: 100%;
}

.dropdown-bg {
  border-radius: 2px;
  background: #444;
  box-shadow: 0px 1px 4px 0px #101010 inset;
}

.patch-dropdown button::after {
  display: none !important;
}

.patch-dropdown button::before {
  display: none !important;
}

.patch-dropdown>span {
  margin-right: 0.5rem;
}

.patch-dropdown div {
  z-index: 1050;
}

.dropdowm-menu {
  min-width: 399px;
  height: auto;
  right: 105% !important;
  top: -100% !important;
  min-width: 400px;
  margin: 0px;
  border: 1px solid #656565;
  background: #444;
  border-radius: 2px;
}

.dropdowm-menu-pos {
  top: -200% !important;
}

.plus-dropdowm-menu {
  height: auto;
  top: 120% !important;
  right: -67% !important;
  padding: 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0px;
  border-top: 1px solid #161616;
  background: #2f2f2f;
  border-radius: 4px;
  z-index: 10000;
}
.connect-dropdowm-menu {
  height: auto;
  top: 109% !important;
  right: -94% !important;
  padding: 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0px;
  border-top: 1px solid #161616;
  background: #2f2f2f;
  border-radius: 4px;
  z-index: 10000;
}

.demo-btn {
  height: auto;
  top: 109% !important;
  right: -127% !important;
  padding: 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0px;
  border-top: 1px solid #161616;
  background: #2f2f2f;
  border-radius: 4px;
  z-index: 10000;
}

.plus-dropdowm-item {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s linear;
}

.plus-dropdowm-item span {
  color: #f6f6f6;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.plus-dropdowm-item:hover {
  background-color: #5a46ff;
}

.dropdown-input {
  border: none;
  padding: 12px !important;
  background: transparent;
  color: #fff !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.dropdown-input:focus {
  box-shadow: none;
  background: transparent;
}

.dropdown-input::placeholder {
  color: #fff !important;
  opacity: 0.5;
}

.inputStyle:focus-visible {
  border-color: red;
  box-shadow: 0 0 0 2px #5A46FF;
}

.dropdown-input+span {
  background: #444;
  border: none;
  border-left: 1px solid #656565;
}

.slider-container {
  width: 30%;
  display: flex;
  align-items: center;
  gap: 3rem;
  position: absolute;
  bottom: 5%;
  left: 3%;
}

.custom-offcanvas {
  position: absolute !important;
  top: 4.2rem !important;
  left: 79px !important;
  color: #d2d2d2;
  width: auto !important;
  background-color: transparent;
  z-index: 100 !important;
  width: 385px !important;
}

.custom-offcanvas::after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  width: 93%;
  height: 5rem;
  /* backdrop-filter: blur(10px); */
}

.activitylog-custom-offcanvas {
  width: 500px !important;
}

.sidebar-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background: #2f2f2f;
}

.sidebar-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: inherit;
  /* border-left: 1px solid #444444; */
  /* margin-left: 1.2rem; */
}

.sidebar-content {
  max-height: 450px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding: 24px 24px 0px 24px;
}

.activitylog-custom-offcanvas .sidebar-content {
  max-height: 100%;
  flex-grow: 1;
}

.sidebar-content p {
  color: #d2d2d2;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.sidebar-content .content {
  display: none;
}

.sidebar-content .content-active {
  display: block;
}

.sidebar-content::-webkit-scrollbar {
  width: 2px;
  background-clip: content-box;
  padding: 10px;
}

.sidebar-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar-content::-webkit-scrollbar-thumb {
  background: #444;
  background-clip: content-box;
  border-radius: 100px;
}

.sidebar-search-container {
  padding: 24px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}

.sidebar-search {
  border-bottom: 1px solid #444;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.sidebar-search-input {
  color: #939393 !important;
  border: none;
  background-color: transparent;
  outline: none;
}

.sidebar-search-input::placeholder {
  color: #939393;
}

.sidebar-search-input:focus {
  background: none;
  outline: none;
  box-shadow: none;
}

.expand-wrapper {
  position: relative;
  z-index: 9 !important;
  display: flex;
  width: 100%;
  background: #2f2f2f;
  font-size: 14px;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  transition: transform 0.3s ease;
  transition: display 0.3s ease;
}

.expand-des {
  height: 69px;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  /* border-left: 1px solid #444444;
  margin-left: 1.2rem; */
  border: 1px solid #444;
  border-left: none;
}

.expand-des h6 {
  margin: 0;
}

.expand-close {
  border: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border: 1px solid #444; */
  padding: 1rem 0.5rem;
  background-color: #2f2f2f;
  position: absolute;
  right: -7%;
  border-radius: 0 20px 20px 0;
}

.activitylog-custom-offcanvas .expand-close {
  right: -4%;
}

.expand-close:hover {
  cursor: pointer;
}

.left-cus-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  z-index: 1 !important;
  position: relative;
  /* border-right: 1px solid #3D3D3D; */
}

.left-cus-sidebar.sidebar-open {
  /* background: #1b1b1b; */
}

.left-cus-sidebar.sidebar-open .left-cus-sidebar-nav .sidebar-analysis:first-child {
  opacity: 1;
  background-color: #2f2f2f !important;
}

.left-cus-sidebar a {
  border-radius: 0px !important;
  color: #fff;
  opacity: 0.5;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  position: relative;
  min-width: 100%;
}

.left-cus-sidebar-nav>div:first-child {
  display: flex;
}

.left-cus-sidebar-nav>div:first-child a {
  border-bottom: 1px solid #444;
}

.left-cus-sidebar-nav>div:first-child>button {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #444;
  background: #2f2f2f;
}

.left-cus-sidebar a:focus,
.left-cus-sidebar a:hover {
  color: #fff !important;
  opacity: 1;
}

/* .left-cus-sidebar.sidebar-open a::after {
  content: "";
  width: 1rem;
  height: 1px;
  background-color: #444444;
  position: absolute; */
/* right: -50%;
  top: 50%;
  transform: translateX(-25px); */
/* right: calc(0% - 1rem);
  top: 50%;
} */

.left-cus-sidebar a.active::after {
  background-color: #d2d2d2;
}

.left-cus-sidebar.sidebar-open a.active {
  background: #2f2f2f;
  /* border-right: 1px solid #d2d2d2; */
  color: #fff !important;
  opacity: 1;
}

.left-cus-sidebar-nav>div {
  width: 100%;
}

.search-result-dropdown button {
  padding: 6px;
}

.search-result-dropdown button:hover {
  background: #5a46ff !important;
}

.search-result-dropdown-result {
  background-color: #1b1b1b;
  padding: 1rem;
  width: 12rem;
}

.search-result-dropdown-result a {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  font-size: 14px;
  padding: 0px;
}

.search-result-dropdown-result a:hover {
  background: transparent;
  color: #fff;
}

.search-result-dropdown-result a div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.search-result-dropdown-result svg {
  width: 25px;
}

.sidebar-search-res {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.btn-remove-default-style {
  background: no-repeat;
  box-shadow: none;
  border: none;
}

.up-down-btn:hover {
  background: #1b1b1b;
  border-radius: 50%;
}

.highlight-text {
  background-color: #ffff00;
  color: #444444;
}

.ai-wrapper {
  position: relative;
}

.ai {
  flex-direction: row !important;
  justify-content: center;
  font-size: 12px !important;
  font-weight: 900 !important;
  min-height: 60px;
  border-radius: 4px !important;
  background: #454545;
}

.ai.active {
  background-color: #181818;
  border-right: 2px solid transparent !important;
  border-radius: 0px !important;
  border-image: linear-gradient(0.15deg,
      #001aff -6.98%,
      #00c2ff 18.03%,
      #00ff94 77.13%,
      #dbff00 114.47%) 1;
}

.ai svg {
  border-radius: 50%;
}

.ai.active svg {
  box-shadow: 0px 0px 37.89894485473633px 0px #ffd601;
}

.ai:focus,
.ai:hover {
  color: #fff;
}

.ai-nav {
  position: absolute;
  top: -10%;
  right: 104%;
  min-width: 57px;
}

.ai-btn {
  font-weight: 700 !important;
  line-height: normal;
  letter-spacing: 0.1px !important;
  border-radius: 4px;
  background: #323131 !important;
  padding: 12px 16px !important;
  opacity: 0.6;
}

.ai-btn:hover {
  opacity: 1;
}

.user-img {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}
.user-img:hover{
  border: 2px solid rgba(90, 70, 255, 1);
}
.user-img-highlight {
  border: 2px solid rgba(90, 70, 255, 1);
}
.user-dropdown-toggle {
  height: auto;
  top: 92% !important;
  right: 4px !important;
  background-color: rgba(24, 24, 24, 1);
  z-index: 10000 !important;
}
.profile-img{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.user-dropdown-toggle a {
  color: rgba(246, 246, 246, 1) !important;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  margin: 0px;
  padding: 0px;
  max-width: 378px;
  white-space: normal;
  word-break: normal;
  overflow-wrap: break-word;
  line-height: 1.3rem;
}

.user-dropdown-toggle a:hover {
  background: rgb(90,70,255) !important;
  opacity: 1;
}

.project_name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: 1px solid #3d3d3d;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.5px);
  margin-left: 250px;
  /* In NavbarUI there's left, center and right aligned sections.
  Right aligned items have increased which has led to position project name in the center of center aligned items.
  Hence, added margin-left to align the project name */
}
.project_name_infra {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: 1px solid #3d3d3d;
  box-sizing: border-box;
  background: rgba(18, 20, 31, 1);
  backdrop-filter: blur(7.5px);
}

.project_name span {
  /* max-width: 10rem; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.edit_project_name {
  background-color: #161616 !important;
}
.btn-icon {
  border: 1px solid #8c8c8c;
  display: flex;
  text-align: center;
  color: #fff !important;
  padding: 0.75rem;
  border-radius: 3px;
  text-decoration: none;
  gap: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.btn-icon-blue {
  border: 1px solid #5a46ff;
  background-color: #5a46ff;
}

.btn-icon span {
  margin: 0 !important;
}

.button-wrapper {
  display: flex;
  gap: 0.1rem;
  height: 100%;
  z-index: 2;
}

.sub_menu_dropdown {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.sub_menu_dropdown_toggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.sub_menu_dropdown_toggle span {
  font-size: 14px;
}

/* =============================================== */
.markup-navigation-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.markup-navigation-wrapper button,
.markup-navigation-wrapper a {
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  padding: 12px 16px;
  border: 1px solid #fff;
}
/* =============================================== */

.popup-wrapper {
  color: #fff;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  border-radius: 4px;
  padding: 0.8rem 1rem;
  border: 1px solid #696969;
  background: #2f2f2f;
  max-width: 300px;
  top: 50%;
  right: 0%;
}

.expand-btn {
  padding: 0.5rem 0.8rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: none;
  background: #2f2f2f;
  height: 39px;
  border-radius: 0 20px 20px 0;
}

/* Default width for larger screens */
.cus-side {
  width: 5.5%;
}

.cus-side2 {
  width: 94.5%;
}

/* Media query for 1024px screens */
@media (max-width: 1024px) {
  .cus-side {
    width: 7.7%;
  }

  .cus-side2 {
    width: 92.3%;
  }
}

/* Media query for 768px screens */
@media (max-width: 768px) {
  .cus-side {
    width: 10.3%;
  }

  .cus-side2 {
    width: 89.7%;
  }
}
.spinner {
  padding: 6px;
}
.activity-log-btn{
  display: flex;
  flex-direction: column;
}
.views-name{
  display: none;
}
.views-display{
  display: block;
}

.ques-askai-btn {
  cursor: pointer;
  border: 1px solid rgba(128,128,128,1);
  background: linear-gradient(90deg, #1b05f9 0%, #f60 100%);
  border-radius: 4px;
  width: 25px !important;
  height: 25px;
  text-align: center;
  padding-left: 4px;
  line-height: 20px;
  margin-left: 10px;
}

.ques-askai-btn:hover {
  border: 1px solid white;
}
