.container {
    text-align: center;
    padding: 20px;
  }
  
.container-title {
    text-align: center;
    font-weight: 300;
    font-size: 48px;
    font-family: 'Work Sans', sans-serif;
    margin: 0;
}

.container-description {
    font-family: 'Work Sans', sans-serif;
    text-align: left;
    margin: 0;
}

.example-json {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 16px;
    margin-top: 16px;
    margin-left: 50px;
    font-family: "Work Sans", Courier, monospace;
    color: #d63384;
    overflow-x: auto;
    position: relative;
    width: 65%;
}

.copy-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 12px;
    height: 12px;
    transition: opacity 0.2s ease-in-out;
}

.copy-icon:hover {
    opacity: 0.7;
}
  