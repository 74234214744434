.dialogWrapper {
    background-color: #1e1e1e;
    border:1px solid #1e1e1e;
    position: fixed;
    border-radius: 5px;
    padding: 10px 0;
    min-width:120px;
    text-align: center;
    font-size: 12px;
}

.dialogWrapper_options {
    font-size: 12px;
    padding: 5px 20px;
    margin-bottom:1px;
    color:#fff;
    cursor:pointer;
    font-weight:600;
    font-family:'DM Sans';
}

.dialogWrapper_options:hover {
    background-color: #2f2f2f;
}