.product-container{
    width: 100%;
}

.product-bg{
    background-color: #0B0D15;
}

.product-header{
    width: 100%;
    display: flex;
    background-color: #FFFFFF;
    justify-content: space-between;
    align-items: center;
}

.product-logo{
    margin-left: 220px;
}

.sign-up-btn{
    display: inline-block;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    font-size: var(--size-2x);
    text-decoration: none;
    background-color: var(--clr-purple);
    color: #f1f5f9;
    padding: 16px 24px;
    border-radius: 4px;
    transition: transform 0.3s, box-shadow 0.2s;
    line-height: 14px;
    width: 120px;
    cursor: pointer;
    margin-right: 140px;
}

.product-image-container{
    width: 100%;
    height: 100vh;
    position: relative;
}

.product-new-image{
    width: 100%;
}

.code-view-image{
    position: absolute;
    right: 6px;
    top: 74%;
}

.product-text-container{
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 28%;
    color:#FFFFFF;
    text-align: left;
    font-family: Work Sans;
    width: 40%;
    left: 12%;
}

.product-image-mobile{
    display: none;
}

.product-title{
    font-size: 32px !important;
}

.product-subtitle-main{
    font-size: 48px !important;
}

.product-subtitle-highlight{
    font-size: 72px !important;
    color: #B5ACFF;
}

.product-description{
    font-size: 24px;
    color: #858585;
}

.btn-for-signup-beta{
    display: inline-block;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    font-size: var(--size-2x);
    text-decoration: none;
    background-color: var(--clr-purple);
    color: #f1f5f9;
    padding: 16px 24px;
    border-radius: 4px;
    transition: transform 0.3s, box-shadow 0.2s;
    line-height: 20.8px;
    width: 200px;
    margin-top: 25px;
    z-index: 1;
    cursor: pointer;
}

/* architecture-container */

.architecture-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 300px;
}

.architecture-title{
    font-size: 64px;
    color: #FFFFFF;
    text-align: center;
}

.architecture-description{
    font-size: 24px;
    color: #B4B4B4;
    max-width: 60ch;
    text-align: center;
    margin-top: 15px;
}

.product-section{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.product-info{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.product-text{
    font-size: 32px;
    color: #FFFFFF;
}

.product-images{
    display: flex;
    flex-direction: column;
    position: relative;
}

.product-frame{
    position: absolute;
    top: 93%;
}

.prime-benefits-container{
    padding-top: 100px;
    position: relative;
}

.prime-benefits-container-bg{
    position: absolute;
    width: 100%;
    height: 1440.4px;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, #930078 11.14%, #7722B9 54.23%, #5A46FF 100%);
    opacity: 0.1;
    filter: blur(222px);
}

.prime-benefits-header{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 150px;
}

.prime-benefits-title{
    font-size: 40px;
    color: #FFFFFF;
    font-family: Work Sans;
}

.line_rotated{
    transform: rotate(180deg);
}

.benefits-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 100px;
    row-gap: 80px;
}

.benefit-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    width: 28%;
}

.benefit-title{
    font-size: 32px;
    max-width: 15ch;
    text-align: center;
}

.benefit-description{
    font-size: 16px;
    color: #B4B4B4;
    max-width: 34ch;
    text-align: center;
}

.read-more{
    color: #000000;
    padding-top: 20px;
    cursor: pointer;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* feature section */

.features-section{
    padding-top: 150px;
    position: relative;
}

.features-section-bg{
    position: absolute;
    width: 550.82px;
    height: 1176.5px;
    left: 0;
    top: 0;
    background: #0B0067;
    filter: blur(333px);
}

.features-grid{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:  center;
    position: relative;
}

.title-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: -33px;
    color: #FFFFFF;
    background-color: #0B0D15;
    padding: 0px 7px;
}

.notable-heading{
    font-size: 40px;
    font-family: Work Sans;
}

.features-container{
    width: 68%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding-top: 6rem;
    padding-bottom: 2rem;
    border-radius: 16px;
    border: 1px dotted #7474D9;
    border-bottom: none;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius:9px;
}

.feature-card{
    display: flex;
    flex-direction: column;
    background: #0B091C;
    border-radius: 16px;
    padding: 30px;
    z-Index: 1;
    width: 70%;
}

.icon-container{
    display: flex;
    justify-content: flex-start;
}

.description{
    color: #B4B4B4;
    font-family: 'Work Sans', sans-serif;
    font-size: 24px;
    text-align: left;
    max-width: 32ch;
}

.container-wrapper{
    display: flex;
    justify-content: space-between;
    width: 67%;
    z-index: 1;
}

.divider-left{
    border-top: 1px dotted #7474D9;
    border-right: 1px dotted #7474D9;
    width: 13.4%;
    height: 203px;
    border-top-right-radius: 16px;
}

.feature-card-container{
    display: flex;
    width: 75%;
    justify-content: center;
    border-bottom: 1px dotted #7474D9;
    padding-bottom: 35px;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    margin-bottom: 2rem;
}

.divider-right{
    border-top: 1px dotted #7474D9;
    border-left: 1px dotted #7474D9;
    width: 13.8%;
    height: 202px;
    border-top-left-radius: 16px;
}

/* bridge-container */

.bridge-container{
    padding-top: 150px;
    position: relative;
}

.bridge-header-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bridge-main-title{
    font-size: 64px;
    font-family: Work Sans;
    color: #FFFFFF;
}

.bridge-sub-title{
    color: #B4B4B4;
    font-size: 24px;
    max-width: 45ch;
}

.bridge-cards-section{
    padding-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bridge-cards-container{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #272727;
    border-radius: 8px;
}

.bridge-left-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.bridge-right-card{
    border-left: 1px solid #272727;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.bridge-card-content{
    padding: 20px;
}

.bridge-card-header{
    text-align: left;
    display: flex;
    align-items: center;
}

.bridge-card-header-text{
    font-size: 16px;
    color: #9E9E9E;
    padding-left: 8px;
}

.bridge-card-title{
    color: #FFFFFF;
    font-size: 24px;
    max-width: 17ch;
    text-align: left;
    padding-top: 10px;
}
.bridge-card-description{
    color: #818284;
    font-size: 16px;
    max-width: 35ch;
    text-align: left;
    padding-top: 10px;
}

.bridge-card-image{
    padding: 20px;
    padding-left: 84px;
}

.decor-container{
    position: absolute;
    top: 28%;
    right: 0;
}

/* Edges */


.edge-section-container{
    padding-top: 100px;
}

.header-container{
    position: relative;
}

.header-text{
    color: #DFDEE0;
    font-size: 40px;
    font-family: 'Work Sans';
}

.header-edge{
    font-weight: 100;
    font-style: italic;
    padding: 0 8px;
    font-family: 'Work Sans';
}

.edge-img{
    position: absolute;
    top: -14px;
    right: calc(50% - 120px);
}

.image-container{
    padding-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 140px;
    z-index: 1;
    position: relative;
}

.image-container-bg{
    position: absolute;
    width: 100%;
    height: 47.53px;
    bottom: 60px;
    background: #FA00FF;
    filter: blur(111px);
}

.subscribe-container{
    margin-top: 80px;
    height: 200px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.subscribe-container-bg{
    position: absolute;
    width: 50%;
    top: 0;
    bottom: 20px;
    background: #6B006D;
    filter: blur(265px);
}

.subscribe-text{
    font-size: 24px;
    font-family: 'Work Sans';
    color: #FFFFFF;
    margin-bottom: 10px;
    z-index: 2;
}

.email-container{
    display: flex;
    align-items: center;
    border: 1px solid #E0E0E0;
    padding: 3px;
    border-radius: 8px;
    z-index: 1;
}

.email-input{
    padding: 6px 8px;
    border: none;
    background: transparent;
    color: #FFFFFF;
    outline: none;
    width: 300px;
    font-size: 16px;
    font-family: 'Work Sans';
}

.subscribe-button{
    background: #FFFFFF;
    color: #0B0D15;
    font-size: 16px;
    font-family: 'Work Sans';
    font-weight: 500;
    border-radius: 4px;
    padding: 4px 12px;
    border: none;
    cursor: pointer;
}


@media screen and (max-width: 1024px){
    .product-logo{
        margin-left: 40px;
    }

    .sign-up-btn{
        margin-right: 40px;
    }

    .product-text-container{
        width: 60%;
    }

    .product-title{
        font-size: 40px;
    }

    .product-subtitle-main,
    .product-subtitle-highlight{
        font-size: 56px;
    }

    .product-description{
        font-size: 20px;
    }

    .architecture-container{
        padding-top: 200px;
        padding: 200px 24px 0 24px;
    }

    .architecture-title{
        font-size: 48px;
    }

    .architecture-description{
        font-size: 20px;
        max-width: 50ch;
    }

    .product-text{
        font-size: 28px;
    }

    .product-section{
        flex-direction: column;
        gap: 30px;
    }

    .product-frame{
        top: 90%;
        width: 90%;
    }

    .prime-benefits-container{
        padding-top: 80px;
    }

    .prime-benefits-header{
        padding-top: 100px;
    }

    .prime-benefits-title{
        font-size: 32px;
    }

    .benefit-item{
        width: 45%;
    }

    .benefit-title{
        font-size: 28px;
    }

    .features-container{
        width: 90%;
    }

    .container-wrapper{
        width: 90%;
    }

    .description{
        font-size: 20px;
    }

    .notable-heading{
        font-size: 32px;
    }

    .bridge-container{
        padding-top: 100px;
        padding: 100px 20px 0 20px;
    }

    .bridge-main-title{
        font-size: 48px;
        text-align: center;
    }

    .bridge-sub-title{
        font-size: 20px;
        text-align: center;
        padding: 0 20px;
    }

    .bridge-cards-container{
        flex-direction: column;
        width: 100%;
        max-width: 800px;
    }

    .bridge-right-card{
        border-left: none;
        border-top: 1px solid #272727;
    }

    .bridge-card-image{
        padding-left: 20px;
    }

    .bridge-card-image img{
        width: 100%;
        height: auto;
    }

    .decor-container{
        display: none;
    }

    .edge-section-container{
        padding: 80px 20px 3rem;
    }

    .header-container{
        text-align: center;
    }

    .header-text{
        font-size: 32px;
    }

    .edge-img{
        right: calc(50% - 100px);
        width: 80px;
    }

    .image-container{
        padding-top: 50px;
        column-gap: 40px;
        flex-wrap: wrap;
        row-gap: 40px;
    }

    .image-container img{
        width: 250px;
        height: auto;
    }

    .subscribe-container{
        padding-top: 100px;
    }

    .email-container{
        width: 80%;
        max-width: 400px;
    }
}

/* Mobile Styles */
@media screen and (max-width: 768px){
    .product-image-container{
        width: 100%;
        height: auto;
    }

    .product-header{
        margin-top: 16px;
        padding: 0 16px;
    }

    .product-logo{
        margin-left: 0;
    }

    .product-logo img{
        width: 120px;
        height: auto;
    }

    .sign-up-btn{
        margin-right: 0;
        padding: 12px 16px;
        width: auto;
        font-size: 14px;
    }

    .product-new-image{
        display: none;
    }

    .product-image-mobile{
        display: block;
        width: 100%;
        height: auto;
    }

    .product-text-container{
        position: relative;
        width: 90%;
        top: 0;
        left: 5%;
        padding: 50px 0;
    }

    .product-title{
        font-size: 32px;
        margin-bottom: 12px;
    }

    .product-subtitle-main,
    .product-subtitle-highlight{
        font-size: 40px;
        margin-bottom: 12px;
    }

    .product-description{
        font-size: 18px;
        margin-bottom: 20px;
    }

    .btn-for-signup-beta{
        width: 100%;
        text-align: center;
    }

    .code-view-image{
        position: static;
        width: 90%;
        margin-top: -12px;
    }

    .architecture-container{
        padding: 10px 16px 0 16px;
    }

    .architecture-title{
        font-size: 22px;
    }

    .architecture-description{
        font-size: 16px;
        max-width: 100%;
    }

    .product-text{
        font-size: 24px;
    }

    .product-images{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-info span img{
        display: none;
    }

    .product-images img{
        width: 80%;
        height: auto;
    }

    .prime-benefits-header{
        padding-top: 80px;
        flex-wrap: wrap;
    }

    .prime-benefits-title{
        font-size: 28px;
    }

    .line, .line_rotated{
        display: none
    }

    .benefits-container{
        padding-top: 60px;
        row-gap: 40px;
    }

    .benefit-item{
        width: 100%;
        padding: 0 20px;
    }

    .benefit-title{
        font-size: 24px;
    }

    .benefit-description{
        font-size: 14px;
    }

    .features-section{
        padding-top: 100px;
    }

    .features-container{
        width: 95%;
        gap: 15px;
        border: none;
    }

    .feature-card{
        width: 100% !important;
        padding: 20px;
    }

    .description{
        font-size: 18px;
    }

    .container-wrapper{
        flex-direction: column;
        align-items: center;
        width: 95%;
    }

    .divider-left, .divider-right{
        display: none;
    }

    .feature-card-container{
        width: 100%;
        border: none;
        padding: 0%;
    }

    .bridge-container{
        padding-top: 80px;
    }

    .bridge-main-title{
        font-size: 36px;
        padding: 0 20px;
    }

    .bridge-sub-title{
        font-size: 18px;
        max-width: 100%;
    }

    .bridge-cards-section{
        padding-top: 40px;
    }

    .bridge-left-card,
    .bridge-right-card{
        padding: 10px;
    }

    .bridge-card-content{
        padding: 15px;
    }

    .bridge-card-title{
        font-size: 20px;
        max-width: 100%;
    }

    .bridge-card-description{
        font-size: 14px;
        max-width: 100%;
    }

    .bridge-card-header-text{
        font-size: 14px;
    }

    .bridge-card-image{
        padding: 10px;
        width: 100%;
    }

    .edge-section-container{
        padding: 40px 16px 2rem;
    }

    .header-text{
        font-size: 20px;
    }

    .edge-img{
        right: calc(50% - 80px);
        width: 60px;
        top: -10px;
    }

    .image-container{
        padding-top: 40px;
        flex-direction: column;
        gap: 30px;
    }

    .image-container img{
        width: 100%;
        max-width: 300px;
    }

    .image-container-bg{
        height: 30px;
        bottom: 40px;
    }

    .subscribe-container{
        padding-top: 40px;
    }

    .subscribe-text{
        font-size: 20px;
        text-align: center;
    }

    .email-container{
        width: 90%;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
    }

    .email-input{
        width: 100%;
        padding: 8px;
        text-align: center;
    }

    .subscribe-button{
        width: 100%;
        padding: 8px;
    }
}

/* Small Mobile Styles */
@media screen and (max-width: 480px){
    .product-header{
        margin-top: 12px;
        gap: 16px;
    }

    .product-logo img{
        width: 100px;
    }

    .sign-up-btn{
        padding: 8px 12px;
        font-size: 12px;
    }

    .product-text-container{
        padding: 50px 0;
    }

    .product-title{
        font-size: 24px;
    }

    .product-subtitle-main,
    .product-subtitle-highlight{
        font-size: 32px;
    }

    .product-description{
        font-size: 16px;
        line-height: 1.5;
    }

    .btn-for-signup-beta{
        padding: 12px 16px;
        font-size: 14px;
    }

    .architecture-container{
        padding: 10px 16px 0 16px;
    }

    .architecture-title{
        font-size: 22px;
    }

    .architecture-description{
        font-size: 14px;
        text-align: center;
        margin-top: 10px;
    }

    .product-text{
        font-size: 20px;
    }

    .prime-benefits-container{
        padding-top: 40px;
    }

    .prime-benefits-header{
        padding-top: 70px;
    }

    .prime-benefits-title{
        font-size: 24px;
    }

    .benefit-title{
        font-size: 20px;
    }

    .notable-heading{
        font-size: 24px;
    }

    .features-container{
        padding-top: 4rem;
    }

    .description{
        font-size: 16px;
    }

    .prime-benefits-container-bg{
        width: 100%;
        height: 100%;
    }

    .features-section-bg{
        width: 100%;
        height: 100%;
    }

    .bridge-container{
        padding-top: 60px;
    }

    .bridge-main-title{
        font-size: 28px;
    }

    .bridge-sub-title{
        font-size: 16px;
    }

    .bridge-cards-section{
        padding-top: 30px;
    }

    .bridge-cards-container{
        border: none;
    }

    .bridge-left-card,
    .bridge-right-card{
        border: 1px solid #272727;
        border-radius: 8px;
        margin-bottom: 20px;
    }

    .bridge-card-content{
        padding: 10px;
    }

    .bridge-card-title{
        font-size: 18px;
        padding-top: 8px;
    }

    .bridge-card-description{
        font-size: 13px;
        padding-top: 8px;
    }

    .bridge-card-header img{
        width: 20px;
        height: auto;
    }

    .bridge-card-image{
        padding: 10px;
    }

    .edge-section-container{
        padding: 20px 12px 2rem;
    }

    .header-text{
        font-size: 20px;
    }

    .edge-img{
        right: calc(50% - 60px);
        width: 50px;
        top: -3px;
    }

    .image-container{
        padding-top: 30px;
    }

    .image-container img{
        max-width: 250px;
    }

    .subscribe-container{
        padding-top: 10px;
    }

    .subscribe-text{
        font-size: 18px;
    }

    .subscribe-container-bg{
        height: 200px;
    }

    .email-container{
        width: 95%;
    }
}
