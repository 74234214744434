.signUpDemoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;
    gap: 16px;
    width: 714px;
    margin: auto;
}

.confirmPaths {
    display: flex;
}

.signUpVector {
    opacity: 40%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.linkSentCheck {
    width: 120px;
    height: 120px;
}

.signUpLabel {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #EEEEEE;
    opacity: 0.7;
    width: 411.2px;
    height: 96px;
    text-align: center;
}

.createSaaSLabel {
    width: 281.48px;
    height: 38px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #EEEEEE;
    opacity: 0.7;
    margin: auto;
}

.sentEmail {
    width: 411.2px;
    height: 38px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #EEEEEE;
    opacity: 0.7;
}

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 601px;
    padding: 32px;
    background: #EEEEEE;
    border-radius: 24px;
}

.setPasswordLabel {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2E0249;
}

.inputPassword {
    width: 537px;
    height: 27px;
    padding: 0px;
    outline: none;
    border: none;
    background: transparent;
    box-shadow: 0px 1px rgba(0, 0, 0, .5);
    margin-top: 5px;
    margin-bottom: 10px;
}

.inputPassword:focus {
    box-shadow: 0px 2px #A91079;
}

.signUpNextButton {
    width: 537px;
    height: 53px;
    background: #6C63FF;
    border-radius: 16px;
    padding: 16px 64px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #EEEEEE;
    margin-top: 20px;
}

@media (max-width : 991px) {
    .signUpDemoContainer {
        width: auto;
    }

    .formContainer {
        width: 100%;
        height: auto;
        padding: 15px;
    }

    .formContainerDiv,
    .inputPassword,
    .signUpNextButton {
        width: 100%;
    }
}

@media (max-width : 575px) {
    .signUpVector {
        width: 80px;
        margin: 5px 0px;
    }

    .signUpLabel {
        font-size: 30px;
    }

    .createSaaSLabel {
        font-size: 15px;
    }

    .signUpNextButton {
        font-size: 15px;
    }

    .sentEmail {
        padding-inline: 70px;
    }

    .linkSentCheck {
        width: 90px;
        height: auto;
    }
}