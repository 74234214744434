.infrastructure-main{
    color: rgb(160, 159, 159);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}
.first-div{
    padding: 18px;
    display: flex;
    justify-content: space-between;
}
.Infra-text{
    padding-left: 10px;
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
}
.vector-img{
    border: 1px solid rgba(111, 113, 137, 1);
    border-radius: 8px;
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.vector-img img {
    width: 21px;
}
.dropdown-account{
    border: 1px solid rgba(111, 113, 137, 1);
    border-radius: 8px;
    background-color: rgba(18, 20, 31, 1);
    padding: 14px;
    display: flex;
}
.dropdown-account:hover{
    border: 1px solid rgba(111, 113, 137, 1);
    border-radius: 8px;
    background-color: rgba(18, 20, 31, 1);
}
.dropdown-item:focus{
    background-color: #6a6970;
    color: rgba(255, 255, 255, 0.8);
}
.dropdown-toggle::after {
    display: none;
}
.menu-list{
    max-height: 140px;
    background:#343a41;
    max-width: 270px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 999;
}
.menu-list::-webkit-scrollbar{
    width: 6px;
    height: 6px;
}

.menu-list::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 10px;
}

.menu-list::-webkit-scrollbar-thumb{
    background-color: #818B99;
    border-radius: 10px;
}
.dropdown-account.show {
    border: 1px solid rgba(111, 113, 137, 1);
    background-color: rgba(18, 20, 31, 1);
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
    background-color: rgba(18, 20, 31, 1);
    border: 1px solid rgba(111, 113, 137, 1);
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}
.dropdown-item:hover{
    background-color: transparent;
}
.pointerNone {
    pointer-events: none;
}
.account-label{
    background:rgba(18, 20, 31, 1);
    position: absolute;
    top: -16px ;
    left: 11px;
    padding: 6px;
    font-size: 13px;
    color: rgba(111, 113, 137, 1);
    pointer-events: none;
}
.acc-reg-name{
    color: rgba(195, 197, 219, 1);
}
.search-box{
    background-color:rgba(24, 25, 38, 1);
    border-radius: 8px;
    display: flex;
    width: 315px;
    align-items: center;
}
.search-bar{
    background-color: rgba(24, 25, 38, 1);
    color: rgba(111, 113, 137, 1);
    padding: 6px 8px 6px 8px;
    width: 300px;
    border: none;
    outline: none;
}
.search-bar::-webkit-search-cancel-button {
    display: none;
}
.search-img{
    width: 24px;
    margin-left: 15px;
}
.clear-img{
    margin-right: 20px;
    cursor: pointer;
}
.highlighted-text{
    color: rgb(160, 159, 159);
    font-size: 16px;
    font-weight: 800;
}
.infra-resources{
    display: flex;
    justify-content: center;
    align-items: center;
}
.resources-items{
    display: flex;
    gap: 30px;
    background-color: rgba(24, 25, 38, 1);
    border: 1px solid rgba(40, 40, 40, 1);
    width: 1780px;
    height: 775px;
    border-radius: 4px;
    overflow: auto;
}
.resources-items::-webkit-scrollbar{
    width: 6px;
    height: 6px;
}

.resources-items::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 10px;
    width: 100px;
}

.resources-items::-webkit-scrollbar-thumb{
    background-color: rgba(31, 32, 46, 1);
    border-radius: 10px;
}
.resizable-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.region-global-detail{
    display: flex;
    gap: 30px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.universal-res{
    border: 1px solid rgba(40, 40, 40, 1);
    border-radius: 16px;
    min-height: 760px;
    background-color: rgba(18, 20, 31, 1);
    resize: horizontal;
    overflow-x: auto;
    position: relative;
}
.category-count{
    margin-right:14px;
    padding-top: 3px;
    background-color: rgba(45, 46, 71, 1);
    border-radius: 50%;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: 12px;
    opacity: 0.8;
    height: 25px;
    min-width: 25px;
}
.body-data{
    font-weight: 500;
    font-size: 16px;
    color: rgba(138, 140, 145, 1);
    text-align: left;
    white-space: nowrap;
}
.universal-res::-webkit-scrollbar {
    display: none;
}
.universal-res-prev-width::-webkit-scrollbar {
    display: none;
}
.uparrow{
    transform: rotate(180deg);
}
.drag-img{
    margin-top: 41px;
    margin-left: 46%;
    position: absolute;
    cursor: pointer;
    z-index: 1;
}
.drag-img-1{
    padding-top: 41px;
    padding-left: 97%;
    position: absolute;
    cursor: pointer;
    z-index: 1;
}
.right-arrow-img{
    margin-top: 41px;
    margin-left: 27%;
    position: absolute;
    cursor: pointer;
    z-index: 9999;
}
.right-arrow-img1{
    padding-top: 41px;
    padding-left: 70%;
    position: absolute;
    cursor: pointer;
    z-index: 9999;
}
.uni-reg-resource{
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    min-width: 328px;
    padding: 15px;
    border-start-start-radius: 16px;
    border-start-end-radius: 16px;
    background-color: rgba(18, 20, 31, 1);
    border-bottom: 1px solid rgba(40, 40, 40, 1);
}
.azs-vpcs-detail{
    display: flex;
    flex-direction: column;
    position: relative;
}
.azs-details{
    display: flex;
    gap: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.azs-data{
    font-weight:700;
    background:rgba(24, 25, 38, 1);
    padding: 6px;
    border-radius: 8px;
}
.all-category-data{
    background-color: rgba(18, 20, 31, 1);
    border-bottom: 1px solid rgba(40, 40, 40, 1);
    border-radius: 0;
}
.compute-details{
    background-color: rgba(7, 8, 15, 1);
}
.compute-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(18, 20, 31, 1);
    height: 54px;
}
.data-count{
    display: flex;
    gap: 2px;
}
.compute-details-header{
    display: flex;
    justify-content: space-between;
    background-color: rgba(7, 8, 15, 1);
}
.compute-details-header:hover{
    background-color: rgba(18, 20, 31, 1);
    border-radius: 10px;
}
.compute-btn{
    display: flex;
    gap: 10px;
    color: rgba(102, 102, 106, 1);
}
.card-body{
    background-color: rgba(7, 8, 15, 1);
    color: rgba(138, 140, 145, 1);
    padding: 4px;
}
.compute-details-body{
    background-color: rgba(7, 8, 15, 1);
    color: rgba(138, 140, 145, 1);
    border-left: 1px solid rgba(68, 68, 71, 0.4);
    margin-left: 25px;
}
.res-reg-resource{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 328px;
    padding: 9px;
    background-color: rgba(18, 20, 31, 1);
    border-start-start-radius: 16px;
    border-start-end-radius: 16px;
    border-bottom: 1px solid rgba(40, 40, 40, 1);
}
.card-toggle-btn{
 background-color: rgba(7, 8, 15, 1);
 border-radius: 16px;
}
.instances{
    display: flex;
    gap: 10px;
    padding: 5px 0 5px 10px;
    color: rgba(138, 140, 145, 1);
    cursor: pointer;
}
.azs-icon-name{
    display: flex;
    gap: 10px;
}
.uparrow{
    transform: rotate(180deg);
}
.vector-arrow{
    transform: rotate(270deg);
}
.arrow-up{
    transform: rotate(180deg);
}
.search-results {
    position: absolute;
    background-color: rgba(24, 25, 38, 1);
    z-index:999;
    width: 380px;
    max-height: 500px;
    overflow-y: auto;
    top: 11%;
    right: 1%;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    color: rgb(255, 255, 255);
    text-align: left;
    border-radius: 8px;
    scroll-behavior: auto;
    border: 1px solid rgb(61, 59, 59);
}
.search-results::-webkit-scrollbar {
    display: none;
}
.search-result-item {
    overflow-wrap: anywhere;
    display: flex;
    gap: 6px;
    padding: 10px;
    cursor: pointer;
}
.search-result-item:hover {
    background-color: #6a6970;
    color: white;
    border-radius: 8px;
}
.focused{
    background-color: #6a6970;
    color: white;
    border-radius: 8px;
}
.vpcs-data{
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}
.vpcs-parent{
    padding-top: 15px;
    padding-bottom: 15px;
}
.az-allcategory-data::-webkit-scrollbar {
    display: none;
}
.zero-subnets{
    width: 280px;
    height: 64px;
}
.resize-handle {
    position: absolute;
    width: 3px;
    height: 100%;
    right: 0;
    top: 0;
    cursor: ew-resize;
    background-color: rgba(18, 20, 31, 1);
}
.hide-data{
    min-height: 760px;
    border: 2px solid rgba(40, 40, 40, 1);
    width: 50px;
    border-radius: 16px;
    background: rgba(18, 20, 31, 1);

}
.hide-data-name{
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    color: rgba(159, 161, 166, 1);
    padding-bottom: 260px;
    font-size: 18px;
    font-weight: 400;
}
.Infra-links{
    display: flex;
    align-items: center;
    gap: 10px;
}
.Infra-services{
    border: 1px solid rgba(111, 113, 137, 1);
    border-radius: 8px;
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.Infra-costs{
    border: 1px solid rgba(111, 113, 137, 1);
    border-radius: 8px;
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.cost-coverage{
    width: 23px;
}
.region-list{
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.8)
}
.region-count{
    background: rgba(24, 25, 38, 1);
    color:rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    font-weight: 600;
    font-size: 13px;
    height: 25px;
    min-width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.matched-text {
    color: white !important;
    font-weight: 800 !important;
}