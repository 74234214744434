.bg-color > div {
  background-color: #2f2f2f !important;
}

.heading {
  opacity: 0.5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.custom-dropzone {
  padding: 0px !important;
  background-color: transparent !important;
  height: 163px;
  border: none !important;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FFFAFAFF' strokeWidth='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' strokeLinecap='square'/%3e%3c/svg%3e");
}

.custom-dropzone-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.uploaded-files-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.all-uploaded-files {
  max-height: 110px;
  overflow-y: scroll;
  margin-bottom: 5rem;
}

.all-uploaded-files::-webkit-scrollbar {
  width: 10px;
}

.all-uploaded-files::-webkit-scrollbar-track {
  background-color: transparent;
}

.all-uploaded-files::-webkit-scrollbar-thumb {
  background-color: #232323;
  border-radius: 200px;
  background-clip: padding-box;
}

.uploaded-file {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.uploaded-file span {
  font-size: 0.875rem;
}

.uploaded-file-des {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.uploaded-file-cancel {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  line-height:0;
}

.uploaded-file-cancel:hover {
  transform: scale(1.3);
}

.cus-btn {
  padding: 1rem 2rem;
  border: 1px solid #3f51b5;
  background-color: #5a46ff;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.cus-btn:hover {
  background-color: transparent;
  color: #3f51b5;
  transition: background-color 200ms linear;
}

.cus-btn:hover svg path {
  fill: #3f51b5;
}

.cust-progress-bar-wrapper {
  position: relative;
}

.cust-progress-bar-label {
  position: absolute;
  padding: 0.1rem 0.5rem;
  background-color: #FFF;
  border-radius: 4px;
  color: #404145;
  bottom:12px;
  left: 0;
}

.cus-progress-bar {
  background-color: #232323;
  border-radius: 4px;
  height: 0.5rem;
}

.cus-progress-bar > div {
  background-color: #5a46ff !important;
  border-radius: 4px !important;
}
